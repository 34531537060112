@charset "utf-8";

// Define defaults for each variable.

$base-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji", Roboto, Helvetica, Arial, sans-serif !default;
$code-font-family: "Menlo", "Inconsolata", "Consolas", "Roboto Mono", "Ubuntu Mono", "Liberation Mono", "Courier New", monospace;
$base-font-size:   16px !default;
$base-font-weight: 400 !default;
$small-font-size:  $base-font-size * 0.875 !default;
$base-line-height: 1.5 !default;

$spacing-unit:     30px !default;

$table-text-align: left !default;

$page-width:       1000px !default;
$info-width:       280px !default;
$content-width:    $page-width - $info-width - $spacing-unit*4;

$size-switch:      768px !default;


@mixin relative-font-size($ratio) {
  font-size: #{$ratio}rem;
}


// Import pre-styling-overrides hook and style-partials.
@import
  "base",             // Defines element resets.
  "layout"            // Defines structure and style based on CSS selectors.
;
